var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Table',{attrs:{"columns":_vm.columns,"data":_vm.resources,"size":"small","stripe":""},scopedSlots:_vm._u([{key:"LevelOrder",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.level_order)+" ")]}},{key:"ApproverType",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.approver_type.approver_type)+" ")]}},{key:"Approver",fn:function(ref){
var row = ref.row;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(row.approver_type_value),expression:"row.approver_type_value"}],staticClass:"form-control",attrs:{"disabled":""},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(row, "approver_type_value", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[(row.approver_type.approver_type_id == 5)?_vm._l((_vm.logicalPositions),function(logicalPosition,index){return _c('option',{key:index,domProps:{"value":logicalPosition.position_id}},[_vm._v(_vm._s(logicalPosition.position_name_en)+" ")])}):_vm._e(),(row.approver_type_id == 6)?_vm._l((_vm.employees),function(employee,index){return _c('option',{key:index,domProps:{"value":employee.employee_id}},[_vm._v(_vm._s(employee.employee_name_en)+" ")])}):_vm._e()],2)]}},{key:"ApproverTypeCC",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.approver_type_cc.approver_type)+" ")]}},{key:"ApproverCC",fn:function(ref){
var row = ref.row;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(row.approver_type_cc_value),expression:"row.approver_type_cc_value"}],staticClass:"form-control",attrs:{"disabled":""},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(row, "approver_type_cc_value", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[(row.approver_type_cc_id == 5)?_vm._l((_vm.logicalPositions),function(logicalPosition,index){return _c('option',{key:index,domProps:{"value":logicalPosition.position_id}},[_vm._v(_vm._s(logicalPosition.position_name_en)+" ")])}):_vm._e(),(row.approver_type_cc_id == 6)?_vm._l((_vm.employees),function(employee,index){return _c('option',{key:index,domProps:{"value":employee.employee_id}},[_vm._v(_vm._s(employee.employee_name_en)+" ")])}):_vm._e()],2)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }